<template>
    <div id="login" >
      <!-- 登录 -->
        <div class="form" v-if="login">
          <form action="">
            <div class="form_top">
              <h3 >账号登录</h3>
              <p><a href="" @click.prevent.stop='$router.push("/Home")'>主页</a></p>
            </div>
            <!-- 登录框 -->
            <div class="form_ipts" >
              <div class="form_ipt">
                  <img class="form_icon1" src="../../public/images/用户.png">
                  <input type="tel" placeholder="11位手机号" name="tel" id="tel " v-model="form.tel">
              </div>
              <div class="form_ipt">
                 <img class="form_icon2" src="../../public/images/安全.png">
                 <input type="password" placeholder="密码" name="password" id="password" v-model="form.password">
              </div>

            </div>

            <!-- 保存密码，忘记密码 -->
            <div class="Psd">
              <div class="savaPsd">
                <input type= "checkbox" name="" id="">
                <span>保存密码</span>
              </div>
              
            <div class="forgetPsd">
              <a href="" @click.prevent.stop='$router.push("/forgetPsd")'>忘记密码</a>
            </div>
            </div>
            <!-- 登录注册 -->
            <div>

            <div class="login_res" >
              <div class="res" >没有账号?<a href="" @click.prevent.stop="login = !login" style="color:red">注册</a>一个</div>
              <div class="log">
                <button class="log_btn"  @click="submit" type="button" @keydown.enter="submit">登录</button>
              </div>
            </div>

            <!-- 注册 -->

            </div>
            <!-- 登录 -->
          </form>
        </div>
<!-- 注册 -->
    <div class="form" v-else >
          <form action="">
            <div class="form_top">

               <h3>账号注册</h3> <h3 style="color:red">验证码是中山大学英文名大写</h3>
                <!-- 阻止冒泡及默认事件 -->
               <p>已有账号,去<a href="" @click.prevent.stop="login = !login">登录</a>&nbsp;
                <a href="" @click.prevent.stop='$router.back(-1)'>返回主页</a></p>
            </div>

            <!-- 注册框 -->
            <!-- 手机号 -->
            <div class="form_ipts_r" >
               <div class="form_ipt">
                  <img class="form_icon1" src="../../public/images/手机号.png">
                  <input type="tel" placeholder="11位手机号" name="tel" id="tel " v-model="form.tel">
              </div>
              <!-- 用户名 -->
              <div class="form_ipt">
                  <img class="form_icon1" src="../../public/images/用户.png">
                  <input type="text" placeholder="用户名" name="username" id="username " v-model="form.username">
              </div>
              <!-- 密码 -->
              <div class="form_ipt">
                 <img class="form_icon2" src="../../public/images/安全.png">
                 <input type="password" placeholder="密码 包含字母大小写，数字，8位+" name="password" id="password" v-model="form.password">
              </div>
              <div class="form_ipt">
                 <img class="form_icon2" src="../../public/images/安全.png">
                 <input type="password" placeholder="确认密码" name="password_check" id="password_check" v-model="check">
              </div>
              
              <div class="form_ipt">
                 <!-- <img class="form_icon2" src="../../public/images/eye.png"> -->
                 验证码
                 <input type="text" placeholder="例如:ABSU" name="code_check" id="code_check" v-model="code_check">
              </div>
              <div class="form_ipt">
                <img class="form_icon2" src="../../public/images/dep.png">
                  <input type="text" placeholder="学院" name="username" id="username " v-model="form.department">
              </div>

              <div class="extra-space"></div>
            </div>

            <!-- 登录注册 -->
            <div>
            <!-- 注册 -->
            <div class="res_login">
              <div class="log">
                <button class="log_btn" @click="register" type="button">注册</button>
              </div>
            </div>
            </div>

        </form>
      </div>

 </div>
</template>

<script>
import { login, register } from '../API/User.js'
import { mapMutations, mapActions } from 'vuex'
export default {

  name: 'login',
  data () {
    return {
      login: true,
      code_check:'',
      check:'',
      form: {
        tel: '',
        password: '',
        username: '',
        department:''
      }
    }
  },
  methods: {
    ...mapMutations(['upTokenInfo']),
    ...mapActions(['initUserInfo']),

    async submit () {
      var regtel = /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/
      if (this.form.tel === ' ' || this.form.password === '') {
        this.$message({
          showClose: true,
          message: '手机号或密码不能为空',
          type: 'error'
        })
      } else if (regtel.test(this.form.tel)) {
        const { data: res } = await login(this.form)
        if (res.status === 0) {
          this.upTokenInfo(res.data.token)
          this.initUserInfo()
          this.$message({
            showClose: true,
            message: '登录成功',
            type: 'success'
          })
          this.$router.replace({
            path: '/Home',
            name: 'Home'
          })
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          })
        }
      } else {
        this.$message({
          showClose: true,
          message: '请输入正确的手机号',
          type: 'error'
        })
      }
      // event.preventDefault()
    },
    // 注册
    async register () {
      // 注册之后进行登录
      var regtel = /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/
       // 密码强度验证正则表达式
       var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
      if (regtel.test(this.form.tel)) {
        
        if (this.form.password == this.check && this.code_check=='SYSU') {
            // 检查密码强度
          if (!passwordRegex.test(this.form.password)) {
            this.$message({
              showClose: true,
              message: '密码必须包含至少一个大写字母、一个小写字母、一个数字，且长度大于等于8位',
              type: 'error'
            });
            
          }else{
            const { data: res } = await register(this.form)
            if(res.status!=0){
              this.$message({
              showClose: true,
              message: res.message,
              type: 'error'
            })
            }else{
              const { data: res1 } = await login(this.form)
              this.upTokenInfo(res1.data.token)
              this.initUserInfo()
              this.$message({
                showClose: true,
                message: '注册成功',
                type: 'success'
              })
              this.$router.replace({
                path: '/Home',
                name: 'Home'
              })
            }
            
          }

        } else if(this.code_check!='SYSU'){
          this.$message({
            showClose: true,
            message: '验证码错误',
            type: 'error'
          })
        } else if(this.form.password == this.check) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          })
        }
        else {
          this.$message({
            showClose: true,
            message: '两次密码不一致，请重新输入',
            type: 'error'
          })
        }

      } else {
        this.$message({
          showClose: true,
          message: '请输入正确格式的手机号',
          type: 'error'
        })
      }
    },
    // 注册
// async register () {
//   // 密码强度验证正则表达式
//   var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

//   // 手机号格式验证
//   var regtel = /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/;

//   // 检查手机号格式
//   if (!regtel.test(this.form.tel)) {
//     this.$message({
//       showClose: true,
//       message: '请输入正确格式的手机号',
//       type: 'error'
//     });
//     return;
//   }

//   // 检查密码强度
//   if (!passwordRegex.test(this.form.password)) {
//     this.$message({
//       showClose: true,
//       message: '密码必须包含至少一个大写字母、一个小写字母、一个数字，且长度大于等于8位',
//       type: 'error'
//     });
//     return;
//   }

//   // 注册逻辑
//   const { data: res } = await register(this.form);
  
//   // 确认密码与验证码检查
//   console.log(res.status,this.form.password,this.code_check)
//   if (res.status === 0 && this.form.password === this.check && this.code_check === 'SYSU') {
//     const { data: res1 } = await login(this.form);
//     this.upTokenInfo(res1.data.token);
//     this.initUserInfo();
//     this.$message({
//       showClose: true,
//       message: '注册成功',
//       type: 'success'
//     });
//     this.$router.replace({
//       path: '/Home',
//       name: 'Home'
//     });
//   } else if (this.code_check !== 'SYSU') {
//     this.$message({
//       showClose: true,
//       message: '验证码错误',
//       type: 'error'
//     });
//   } else if (this.form.password !== this.check) {
//     this.$message({
//       showClose: true,
//       message: '两次密码不一致，请重新输入',
//       type: 'error'
//     });
//   } else {
//     // 此处的else分支应该不再需要，因为错误情况已在之前的检查中处理
//     this.$message({
//       showClose: true,
//       message: '未知错误，联系管理员',
//       type: 'error'
//     });
//   }
// },
    keyDown (e) {
      if (e.keyCode === 13 && this.$route.name === 'login') {
        this.submit()
      }
    }

  },
  created () {
    // console.log(this.$route.query)
    if (this.$route.query.login === 'register') {
      this.login = false
    } else {
      this.login = true
    }
  },
  mounted () {
    window.addEventListener('keydown', this.keyDown)
  },
  deactivated () {
    window.removeEventListener('keydown', this.keyDown, false)
  }
}
</script>

<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
}

#login{
  width: 100%;
  position: relative;
  background-color: rgba(103, 189, 189, 0.6);
  height:100vh;
  background: url('../assets/images/background.jpg') no-repeat;
  background-size: 100% 100%;
}

@media  all and (orientation: portrait)  { /* 适配较小屏幕，例如手机 */
  #login{
    position: relative;
    background-color: rgba(103, 189, 189, 0.6);
    height:100vh;
    width: 100%;
    background: url('../assets/images/background-phone.jpg') no-repeat;
    // background-size: auto 100%;
    background-size: 100% 100%; /* 调整为cover以填充容器，可能会裁剪图片 */
    // background-position: center left -50px; 
    background-position: center;
    overflow-x: auto; /* 允许水平滚动 */
    display: flex; /* 使用Flex布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }
}
.form{
  // width: 6.8333rem;
  width: 360px;
  // height: 9rem;
  height:auto;
  padding: 1rem,0;
  position: absolute;
  top: 5%;
  right: 12%;
  // background-color:#fff;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius:20px ;
  .form_top{
    margin: 30px;
    h3{
      text-align: left;
    }
  }
  .form_ipts{
    width: 80%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0);
    .form_ipt{
      display: flex;
      justify-content: space-around;
      margin: .5833rem 0 0;
      width: 100%;
      outline: none;
      height: .5833rem;
      // background-color:#F8F8F8;
      background-color: rgba(255, 255, 255, 0);
      .form_icon1{
        display: inline-block;
        width: auto;
        height: 35px;
        flex: 1;
        background-color: rgba(255, 255, 255, 0);

        // background:url('../.././public/images/用户.png');
      }
       .form_icon2{
        display: inline-block;
        // width: .5833rem;
        width: auto;
        height: 35px;
        flex: 1;
        background-color: rgba(255, 255, 255, 0);
      }
      input{
        width:200px ;
        height: 35px;
        border: 0px;
        flex: 6;
        padding-left: 12px;
        outline: none;
        background-color: #F8F8F8;
      }
      input:focus{
        background-color: #fff;
      }
    }
    // password

  }

  // 注册样式
  .form_ipts_r{
    width: 80%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0);
    .form_ipt{
      display: flex;
      justify-content: space-around;
      margin: .5833rem 0 0;
      width: 100%;
      height: .5833rem;
      background-color: rgba(255, 255, 255, 0);
      .form_icon1{
        display: inline-block;
        width: auto;
        height: 35px;
        flex: 1;
        background-color: rgba(255, 255, 255, 0);
        // background:url('../.././public/images/用户.png');
      }
       .form_icon2{
        display: inline-block;
        width: auto;
        height: 35px;
        flex: 1;
        background-color: rgba(255, 255, 255, 0);

      }
      input{
        width:200px ;
        height: 35px;
        border: 0px;
        flex: 6;
        padding-left: 12px;
        outline: none;
        background-color: #F8F8F8;
      }
      input:focus{
        background-color: #fff;
      }
    }
    // password

  }
  // 忘记密码
  .Psd{
    width: 80%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .savaPsd{
      display: flex;
      align-items: center;
      span{
        padding: 0 .05rem
      }
    }
    .forgetPsd{

    }
  }
  // 登录
  .login_res{
    width: 80%;
    display: flex;
    margin: .5333rem auto;
    justify-content: space-between;
    align-items: center;
    .res{
      a{
        padding: 0 .1667rem;
        color: #43dac3;
      }
      a:hover{
        color: #16a18d;
      }
    }
    .log{

      .log_btn{
            padding: 10px 25px;
            border: 0;
            background-color: #409EFF;
            border-radius: 20px;
            color: #fff;
            margin: 0 20px;
            outline: none;
            cursor: pointer;
        }
        .log_btn:hover{
            background-color: #0092EE;
        }
    }
  }
  // 注册
  .res_login{
    height: 70px;
    width: 80%;
    display: flex;
    margin:.2rem auto;
    justify-content: center;
    align-items: center;
    .log{

      .log_btn{
            padding: 10px 25px;
            border: 0;
            background-color: #409EFF;
            border-radius: 20px;
            color: #fff;
            margin: 0 auto;
            outline: none;
            cursor: pointer;
        }
        .log_btn:hover{
            background-color: #0092EE;
        }
    }
  }
}
</style>
